import { useEffect } from "react";
import * as R from "ramda";

import { graphql, useStaticQuery } from "gatsby";
import {
  extractBanner,
  extractImageBlocks,
  extractRichText,
} from "../../common/contentful-extract.jsx";

import "aos/dist/aos.css";
import AOS from "aos";

import Banner from "../Banner/Banner.jsx";
import ImageBlock from "../ImageBlock/ImageBlock.jsx";
import NowFutureSection from "./NowFutureSection.jsx";
import Page from "../Page/Page.jsx";
import Section from "../Section/Section.jsx";

const OurStoryPage = () => {
  const contentfulData = useStaticQuery(query);

  const data = R.compose(
    R.evolve({
      topHeroBanner: extractBanner,
      sectionTitle: extractRichText,
      sectionSubtitle: extractRichText,
      imageBlocks: extractImageBlocks,
      sectionTitleBottom: extractRichText,
      sectionSubtitleBottom: extractRichText,
      imageBlocksBottom: extractImageBlocks,
      bottomBanner: extractBanner,
    }),
    R.defaultTo({}),
    R.prop("contentfulOurStoryPage"),
  )(contentfulData);

  const {
    topHeroBanner,
    sectionTitle,
    sectionSubtitle,
    imageBlocks,
    sectionTitleBottom,
    sectionSubtitleBottom,
    imageBlocksBottom,
    bottomBanner,
  } = data;

  useEffect(() => {
    // https://github.com/michalsnik/aos
    // initialize animation on scroll when the page loads
    AOS.init({
      offset: 100,
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <Page title="Our Story" topHeroBanner={topHeroBanner}>
      <Banner hero {...topHeroBanner} />

      <Section title={sectionTitle} subtitle={sectionSubtitle} aos={true}>
        {imageBlocks.map((imageBlock, i) => {
          const { title } = imageBlock;

          return (
            <ImageBlock
              key={`${i} ${title}`}
              isReverse={i % 2 === 0}
              aos={true}
              {...imageBlock}
            />
          );
        })}
      </Section>
      <NowFutureSection />
      <Section
        title={sectionTitleBottom}
        subtitle={sectionSubtitleBottom}
        aos={true}
      >
        {imageBlocksBottom.map((imageBlock, i) => {
          const { title } = imageBlock;

          return (
            <ImageBlock
              key={`${i} ${title}`}
              isReverse={i % 2 !== 0}
              aos={true}
              {...imageBlock}
            />
          );
        })}
      </Section>

      <Banner aos={true} {...bottomBanner} />
    </Page>
  );
};

export default OurStoryPage;

const query = graphql`
  {
    contentfulOurStoryPage {
      topHeroBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
      }
      sectionTitle {
        raw
      }
      sectionSubtitle {
        raw
      }
      imageBlocks {
        id
        image {
          fluid(maxWidth: 570) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
        }
        title
        text {
          raw
        }
        link {
          ...Link
        }
      }
      sectionTitleBottom {
        raw
      }
      sectionSubtitleBottom {
        raw
      }
      imageBlocksBottom {
        id
        image {
          fluid(maxWidth: 570) {
            aspectRatio
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
            base64
          }
        }
        title
        text {
          raw
        }
        link {
          ...Link
        }
      }
      bottomBanner {
        backgroundImage {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          file {
            url
          }
        }
        backgroundSize
        textColor
        subtitle {
          raw
        }
        title {
          raw
        }
        content {
          raw
        }
        link {
          ...Link
        }
      }
    }
  }
`;
