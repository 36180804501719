import * as R from "ramda";
import { useStaticQuery, graphql } from "gatsby";
import {} from "prop-types";

import "./now-future.scss";
// import "react-tabs/style/react-tabs.css"; don't need this

import { extractTextBlock } from "../../common/contentful-extract.jsx";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Container100 from "../Container/Container100.jsx";
import Section from "../Section/Section.jsx";

const propTypes = {};

const NowFutureSection = (props) => {
  const contentfulData = useStaticQuery(query);

  const data = R.compose(
    R.evolve({
      nowHeader: extractTextBlock,
      nowBlocks: R.map(extractTextBlock),
      futureHeader: extractTextBlock,
      futureBlocks: R.map(extractTextBlock),
    }),
    R.defaultTo({}),
    R.prop("contentfulOurStoryPage"),
  )(contentfulData);

  const sections = [
    {
      tab: "Now",
      header: data.nowHeader,
      blocks: data.nowBlocks,
    },
    {
      tab: "Future",
      header: data.futureHeader,
      blocks: data.futureBlocks,
    },
  ];

  return (
    <Section theme="dark" className="now-future" aos={true}>
      <Container100>
        <Tabs data-aos="fade-up">
          <TabList>
            {sections.map((section) => (
              <Tab key={section.tab}>{section.tab}</Tab>
            ))}
          </TabList>

          {sections.map((section) => {
            return (
              <TabPanel key={section.tab}>
                <div className="now-future__titles" data-aos="fade-up">
                  <h2 className="now-future__title">
                    {R.path(["header", "title"], section)}
                  </h2>

                  <div className="now-future__subtitle">
                    {R.path(["header", "text"], section)}
                  </div>
                </div>

                <div className="now-future__blocks">
                  {section.blocks.map((block, i) => {
                    return (
                      <div
                        key={`${block.title}-${i}`}
                        className="now-future__block"
                        data-aos="fade-up"
                      >
                        <h3 className="now-future__block-title">
                          {block.title}
                        </h3>
                        <div className="now-future__block-text">
                          {block.text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPanel>
            );
          })}
        </Tabs>
      </Container100>
    </Section>
  );
};

NowFutureSection.propTypes = propTypes;
export default NowFutureSection;

const query = graphql`
  query NowFutureSection {
    contentfulOurStoryPage {
      nowHeader {
        title
        text {
          raw
        }
      }
      nowBlocks {
        title
        text {
          raw
          references {
            ...Link
          }
        }
      }
      futureHeader {
        title
        text {
          raw
        }
      }
      futureBlocks {
        title
        text {
          raw
          references {
            ...Link
          }
        }
      }
    }
  }
`;
