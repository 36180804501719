import cx from "classnames";
import { node, object, string, array, bool } from "prop-types";

import "./image-block.scss";

import Container5050 from "../Container/Container5050.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";
import GatsbyImage from "gatsby-image";

const propTypes = {
  aos: bool, // https://github.com/michalsnik/aos
  className: string,
  fluidImage: object.isRequired, // gatsby fluid image
  isReverse: bool,
  title: node.isRequired,
  link: object, // ContentfulLink object
  smallImages: bool, // for homepage pillars section
  text: node.isRequired,
  tags: array,
  buttonModifier: string,
};

const ImageBlock = (props) => {
  const {
    aos,
    className,
    fluidImage,
    isReverse,
    title,
    link,
    smallImages,
    text,
    tags,
    buttonModifier,
    ...otherProps
  } = props;
  /**
   * title/text/link next to an image
   * used for homepage pillars and our-story entry
   */
  return (
    <Container5050
      className={cx("image-block", className, {
        "container--50-50--reverse": isReverse,
        "image-block--small-images": smallImages,
      })}
      left={
        <div
          className="image-block__image"
          data-aos={aos && "fade-up"}
          data-aos-delay={aos && "250"}
        >
          <GatsbyImage fluid={fluidImage} />
        </div>
      }
      right={
        <div className="image-block__text-holder" data-aos={aos && "fade-up"}>
          {tags && <p className="image-block__tag">{tags}</p>}
          <h2 className="image-block__title">{title}</h2>
          <div className="image-block__text fancy-a">{text}</div>
          {link && (
            <ContentfulLink
              {...link}
              className={cx("btn", "btn--transparent", "image-block__link", {
                [`${buttonModifier}`]: buttonModifier,
              })}
            />
          )}
        </div>
      }
      {...otherProps}
    />
  );
};

ImageBlock.propTypes = propTypes;
export default ImageBlock;
