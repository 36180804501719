import { bool, node, object, oneOf, string } from "prop-types";
import cx from "classnames";

import "./banner.scss";
import "./steps-ol.scss";
import "../../scss/global.scss";

import BackgroundImage from "gatsby-background-image";
import Container100 from "../Container/Container100.jsx";
import ContentfulLink from "../ContentfulLink/ContentfulLink.jsx";

const propTypes = {
  aos: bool, // https://github.com/michalsnik/aos
  backgroundSize: oneOf(["", "contain", "cover"]),
  children: node,
  className: string,
  content: node,
  fluidBackground: object, // gatsby fluid image
  hero: bool,
  link: object, // link object for <ContentfulLink>
  style: object,
  subtitle: node,
  textColor: oneOf(["Light", "Dark"]),
  title: node,
  overrideOverlay: bool,
};

const Banner = (props) => {
  const {
    aos,
    backgroundSize,
    children,
    className,
    content,
    fluidBackground,
    hero,
    link,
    style,
    subtitle,
    textColor,
    title,
    overrideOverlay,
    ...otherProps
  } = props;

  // the BackgroundImage won't show up at all unless it has a fluid prop. If we
  // don't have one, just use a div
  const Wrapper = (props) =>
    fluidBackground ? (
      <BackgroundImage
        {...props}
        fluid={fluidBackground}
        style={{
          ...style,
          backgroundSize,
          backgroundOrigin:
            backgroundSize === "contain" ? "content-box" : "border-box",
        }}
      />
    ) : (
      <div {...props} />
    );

  return (
    <Wrapper
      className={cx("banner", className, {
        "banner--hero": hero,
        "banner--dark-text": textColor === "Dark",
      })}
      {...otherProps}
      fluid={fluidBackground}
    >
      {/* //currently unused */}
      {overrideOverlay ||
        (textColor !== "Dark" && backgroundSize === "cover" && (
          <div className="banner__overlay"></div>
        ))}
      <Container100 className="banner__container">
        <div className="banner__titles" data-aos={aos && "fade-up"}>
          <div className="banner__title fancy-bold">{title} </div>
          {subtitle && <div className="banner__subtitle">{subtitle}</div>}
        </div>

        <div data-aos={aos && "fade-up"}>
          {children}

          {content ? (
            <div className="banner__content steps-ol">{content}</div>
          ) : null}

          {link ? (
            <div className="banner__link-container">
              <ContentfulLink
                {...link}
                className={cx("btn", {
                  // if there is a photo background, always use transparent btn
                  "btn--transparent":
                    textColor !== "Dark" && backgroundSize === "cover",
                })}
              />
            </div>
          ) : null}
        </div>
      </Container100>
    </Wrapper>
  );
};

Banner.propTypes = propTypes;
export default Banner;
